import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { AppSettingsService } from '../../../services/app-settings.service';
import { ProductInterface } from '../../../services/product.service';
@Component({
  selector: 'app-product-gallery',
  templateUrl: './product-gallery.component.html',
  styleUrls: ['./product-gallery.component.css']
})
export class ProductGalleryComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: any;
  @Input() private product: ProductInterface;

  public showVideo = false;
  private video: string;
  private updatedImages = [];
  public baseurl: string = "";
  public dispImg:string = "";
  public imgType: any = "medium";
  constructor(private appSetting: AppSettingsService) { }
 
  
  ngOnInit() {
    this.baseurl = this.appSetting.getBaseUrl();
    if (window.innerWidth < 768 ) {
      this.imgType = "medium";
     } else {
       this.imgType = "medium";
     }
	 
	 console.log("product-gallery.components", this.imgType);
    
  }
  ngOnChanges()
  {
    if (window.innerWidth < 768 ) {
      this.imgType = "medium";
    } else {
      this.imgType = "medium";
    }
    
    console.log('Images',this.product);
    if (this.product) {
      //updatedImages = 
      this.updatedImages = [];
      for (const img of this.product.images) {
        if (this.updatedImages.indexOf(img.img_name) === -1) {
          this.updatedImages.push(img.img_name);
        }
      }  

      if(this.updatedImages.length){
        
        if (this.imgType =="medium") {
          let splitImg = this.updatedImages[0].split('.');
          let image = splitImg[0] +'_'+'medium'+'.'+splitImg[1];
          this.dispImg = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+image;
        } else {
          let splitImg = this.updatedImages[0].split('.');
          let image = splitImg[0] +'_'+'medium'+'.'+splitImg[1];
          this.dispImg = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+image;
        }
      
      }
      else{
      this.dispImg = "https://s3.amazonaws.com/inventory-clara/images/nophoto2.jpg";
      }

      if(this.product.video === '' || this.product.video === null){
        this.video = "https://s3.amazonaws.com/inventory-clara/videos/no-video.mp4";
      }
      else{
        this.video = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+this.product.video;
      }
    }
    

  }

  playVideo() {
    this.showVideo = true;
    setTimeout(()=>{
      this.videoplayer.nativeElement.play();
    },100);
  }

  stopVideo() {
    this.showVideo = false;
    this.videoplayer.nativeElement.pause();
  }

  showImg(img:string)
  {
    
    if (this.imgType ==="medium") {
        let splitImg = img.split('.');
        let image = splitImg[0] +'_'+'medium'+'.'+splitImg[1];
        this.dispImg = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+image;
        } else {
        let splitImg = img.split('.');
        let image = splitImg[0] +'_'+'medium'+'.'+splitImg[1];
        this.dispImg = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+image;
        }
    // this.dispImg = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+img;
  }
}
