import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() public showFooter: boolean = true;
  @Output() menuClick = new EventEmitter();
  private year: number;
  constructor() { 
    this.year = (new Date()).getFullYear();
  }

  ngOnInit() {
  }

  mobileToggleMenu()
  {
    console.log('toggle menu');
    this.menuClick.emit();
  }

}
