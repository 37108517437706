import { Component,OnInit,NgModule, Input, Output, EventEmitter, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
// import { DragScrollDirective } from 'ngx-drag-scroll';
import { CarouselConfig,CarouselModule,CarouselComponent } from 'angular-bootstrap-md';
import { Router,RouterModule } from '@angular/router';
import{ ProductService, CategryProductInterface, ProductInterface } from '../../services/product.service';
import{ AppSettingsService } from '../../services/app-settings.service';
import { storeInterface } from '../../services/vendor.service';
import { mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.css']
})

export class ProductSliderComponent implements OnInit {
  
  @ViewChild(CarouselComponent) carouselRef: CarouselComponent;
  // @ViewChild('nav', {read: DragScrollDirective}) ds: DragScrollDirective;
  leftNavDisabled = false;
  rightNavDisabled = false;
  public imgType : any;
  @Input() public rows:any;
  @Input() public categorySlider:CategorySliderInterface;
  @Input() public vendor: storeInterface;
  private slides: Array<Array<CategryProductInterface>> = [];
  private product: ProductInterface;
  public showModal: boolean = false;

  constructor(public appSettings:AppSettingsService, 
    private router:Router, 
    private productService: ProductService) { }

  ngOnInit() {
    this.getSlides();
    console.log('categorySlider', this.categorySlider, this.slides);

    if (window.innerWidth < 768 ) {
     this.imgType = "small";
    } else {
      this.imgType = "medium";
    }
    
  }

  ngOnChanges(changes: any) {}

  moveLeft() {
    // this.ds.moveLeft();
  }

  moveRight() {
    // this.ds.moveRight();
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

  private getSlides() {
    let slidesCount = Math.ceil(this.categorySlider.products.length/this.categorySlider.perSlide);
    for(var i=1; i<=slidesCount; i++) {
      let start = ((i-1) * this.categorySlider.perSlide),
          end = (start + this.categorySlider.perSlide);

      if (end > this.categorySlider.products.length) {
        end = this.categorySlider.products.length;
      }
      
      let slide = this.categorySlider.products.slice(start, end);
      this.slides.push(slide);
    }
    return this.slides;
  }

  productView(id) {
    this.router.navigate(['/product/'+id]);
  }

  openQuickView(product_id) {
    console.log('vendor', this.vendor);
    setTimeout(()=>{
      this.productService.getProductDetails([product_id], this.vendor.vendorId)
      .pipe(
        mergeMap((response) => {
          if (response) {
            let productData = response[product_id];

            if (!productData.images.length) {
              return this.productService.getProductImages(product_id).pipe(
                map((resp: any) => {
                  let data = resp.data || []; 
                  let keys = Object.keys(productData.variants);
                  let images = [];                          
                  for (const key of keys) {
                    let segments = key.split('-');
                    if (segments.length === 3) {
                      segments.pop();
                      let fn = segments.join('-');
                      for (const item of resp.data) {
                        if(item.file_name.indexOf(fn) !== -1) {
                          images.push({
                            variant_id: item.product_variant_id,
                            img_name : item.file_name
                          });
                        }
                      }
                    }
                  }

                  console.log(resp,  images);

                  productData.images = images;
                  response[product_id] = productData;
                  return response;
                })
              );
            } 

            return of(response);
          }
          
        })
      )
      .subscribe((mappedProducts: {[key: string]: ProductInterface})=> {
        if (mappedProducts[product_id]) {
          this.product = mappedProducts[product_id];
        }
        // this.pro = res[product_id];
        // this.colors = this.pro.colors;
        // this.size = this.pro.size;
        // this.images = this.pro.images;
        // console.log('Popup Product',this.pro);
      });
      this.showModal = true;
    },100);
  }

  onCloseHandled(){
    this.showModal= false;
  }
}

export interface CategorySliderInterface {
  title: string;
  perSlide: number;
  products: Array<CategryProductInterface>
}
