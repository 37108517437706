import { Component, OnInit,Output,EventEmitter,Input } from '@angular/core';
import {FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import { IPayPalConfig,ICreateOrderRequest } from 'ngx-paypal';
import { CartService, CartInterface, CartSourceInterface } from '../../../services/cart.service';
import { VendorService, storeInterface } from '../../../services/vendor.service';
import { AppSettingsService } from '../../../services/app-settings.service';
import { PaymentInfoInterface, CheckoutInterface } from '../../../pages/checkout/checkout.component';
import { ContactInfoInterface, AddressInterface } from '../contact-information/contact-information.component';
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit 
{
  @Input() checkout: CheckoutInterface;
  @Input() statesByCountry:any;
  @Output() public nextStep = new EventEmitter<string>();
  @Output() public paymentStatus = new EventEmitter<string>();

  private states: Array<any> = [];
  public paymentForm: FormGroup;
  public creditCardForm: FormGroup;
  public billingAddressForm: FormGroup;
  public billingForm: FormGroup;

  public payPalConfig ? : IPayPalConfig;
  public listOfNames='';

  private paymentFormSubmitted : boolean = false;
  private showCreditCard = false;
  private showPaypal = false;
  private showCompleteBtn = true;
  private showBillingAddress = false;
  private showSuccess = false;
  private showCancel = false;
  private showError = false;
  constructor(private cartService: CartService,private appSetings: AppSettingsService, private vendorService: VendorService) 
  {
    
  }
  
  ngOnInit() {}

    ngOnChanges(changes) {
    console.log('changes', changes);
    // if (changes.checkout && changes.checkout.currentValue ) {
    //   this.populateForms();
    //   this.changePayment(this.checkout.paymentInfo.paymentMethod);      
    // }

    if (changes.checkout && changes.checkout.currentValue && changes.checkout.currentValue.billingAddrees && changes.checkout.currentValue.billingAddrees.country) {
      if (this.statesByCountry[this.checkout.billingAddrees.country]) {
        this.states = this.statesByCountry[this.checkout.billingAddrees.country];
      }
      
      this.populateForms();
      this.initPayaplaConfig();
      this.changePayment(this.checkout.paymentInfo.paymentMethod);
    }
  }

  populateForms() {
    // Credit card for Authorize
  //  let expiryRegex = `^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$`;

    this.creditCardForm = new FormGroup({
      card_number: new FormControl(this.checkout.paymentInfo.cardDetails.cardNumber,[Validators.required,CustomValidators.number,Validators.minLength(16)]),
      name: new FormControl(this.checkout.paymentInfo.cardDetails.cardHolderName,[Validators.required]),
      exp_date: new FormControl(this.checkout.paymentInfo.cardDetails.expiry,[Validators.required,Validators.pattern('(0[1-9]|1[0-2])\/[0-9]{2}')]),
      cvv: new FormControl(this.checkout.paymentInfo.cardDetails.cvv,[Validators.required,CustomValidators.number,Validators.maxLength(4)]),
    });

    this.billingAddressForm = new FormGroup({
      firstName: new FormControl(this.checkout.billingAddrees.firstName,[Validators.required]),
      lastName: new FormControl(this.checkout.billingAddrees.lastName,[Validators.required]),
      address1: new FormControl(this.checkout.billingAddrees.address1,[Validators.required]),
      address2: new FormControl(this.checkout.billingAddrees.address2,[Validators.required]),
      city: new FormControl(this.checkout.billingAddrees.city,[Validators.required]),
      country: new FormControl(this.checkout.billingAddrees.country,[Validators.required]),
      state: new FormControl(this.checkout.billingAddrees.state,[Validators.required]),
      zipcode: new FormControl(this.checkout.billingAddrees.zipcode,[Validators.required,CustomValidators.number,Validators.maxLength(5)]),
      phone: new FormControl(this.checkout.billingAddrees.phone,[Validators.required,CustomValidators.number,Validators.maxLength(15),Validators.minLength(10)]),
    });
    
    // payment form
    this.paymentForm = new FormGroup({
      payment_method: new FormControl(this.checkout.paymentInfo.paymentMethod, [Validators.required]),
      credit_card: this.creditCardForm
    });

    // billing address form
    this.billingForm = new FormGroup({
      billing_type: new FormControl(this.checkout.billingAddreesType,[Validators.required]),
      billing_address: this.billingAddressForm
    });

    
  }

  countryChange(event) {
    this.states = [];
    if (this.statesByCountry[event.target.value]) {
      this.states = this.statesByCountry[event.target.value];
    }
  }

  goNext(str:string)
  {
    console.log(this.paymentForm.value, this.paymentForm, this.billingForm);

    if(this.billingForm.value.billing_type === 'B') {
      this.paymentFormSubmitted = true;
      if(this.billingForm.invalid){
        return false;
      }
    }

    
    if( (
          (this.paymentForm.value.payment_method === 'credit_card'  && this.paymentForm.status === 'VALID')
          || this.paymentForm.value.payment_method === 'paypal'
        ) && 
        (
          (this.billingForm.value.billing_type === 'B' && this.billingForm.status === 'VALID') 
          ||
          this.billingForm.value.billing_type === 'SAS'
        )
    )
    {
      // this.formValue.emit(this.paymentForm.value);
      this.checkout.paymentInfo.paymentMethod = this.paymentForm.value.payment_method;
      this.checkout.paymentInfo.cardDetails = {
        cardNumber: this.paymentForm.value.credit_card.card_number,
        cardHolderName: this.paymentForm.value.credit_card.name,
        expiry: this.paymentForm.value.credit_card.exp_date,
        cvv: this.paymentForm.value.credit_card.cvv
      };
      this.checkout.billingAddreesType = this.billingForm.value.billing_type;
      if (this.billingForm.value.billing_type === 'B') {
        this.checkout.billingAddrees = this.billingForm.value.billing_address;
      }

      this.nextStep.emit(str);
    }
  }

  goPrev(str:string){
    this.nextStep.emit(str);
  }

  changePayment(method:string)
  {
    if (method === 'credit_card') {
      this.showPaypal = false;
      this.showCreditCard = this.showCompleteBtn = true;
      
    } else if(method === 'paypal') {
      this.showCreditCard = this.showCompleteBtn = false;
      this.showPaypal = true;
    }
  }

  showBilling(type:string) {
    if (type === 'B') {
      this.showBillingAddress = true;
    } else {
      this.showBillingAddress = false;  
    }
  }

  private initPayaplaConfig(): void 
  {

    if (!this.checkout || !this.checkout.store || !this.checkout.store.paypal) 
    {
      console.log('No paypal configuration....');
      return;
    }

 
   let orderData: any = {
	 amount: {
              currency_code: 'USD',
              value: this.checkout.orderTotal,
              breakdown: {
				  discount: {
                      currency_code: 'USD',
                      value: this.checkout.discount
                  },
				  shipping: {
                      currency_code: 'USD',
                      value: this.checkout.shippingCost
                  },
                  item_total: {
                      currency_code: 'USD',
                      value: this.checkout.cart.cartTotal
                  }
              }
          },
		  items: []

  }


  for (const item of this.checkout.cart.cartItems) {
 
      let citems: any = {
        name: item.name + " ["+ item.sku+"]",
        quantity: '1',
        //category: '',
        unit_amount: {
            currency_code: 'USD',
            value: item.price
        },
      }
      orderData.items.push(citems);
	  
    }

     console.log("orderdata:" + orderData);
	
    this.payPalConfig = {
        currency: 'USD',
        clientId: this.checkout.store.paypal.clientId,
        createOrderOnClient: () => <ICreateOrderRequest> {
            intent: 'CAPTURE',
            purchase_units: [orderData],

        },
        advanced: {
            commit: 'true'
        },
        style: {
            label: 'paypal',
            layout: 'vertical'
        },
        onApprove: (data, actions) => {
            console.log('onApprove - transaction was approved, but not authorized', data, actions);
            actions.order.get().then(details => {
                console.log('onApprove - you can get full order details inside onApprove: ', details);
            });

        },
        onClientAuthorization: (data) => {
            console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
            let transId = data.id;

            if(transId){

              if ((this.billingForm.value.billing_type === 'B' && this.billingForm.status === 'VALID')) 
              {
                this.checkout.billingAddreesType = this.billingForm.value.billing_type;
                this.checkout.billingAddrees = this.billingForm.value.billing_address;
              }

              this.paymentStatus.emit(transId);
              setTimeout(()=>{
                //this.goNext('complete');
              },1000);

            } else {

               alert('Something went wrong!.');
            }
            
            //this.showSuccess = true;
        },
        onCancel: (data, actions) => {
            console.log('OnCancel', data, actions);
            this.showCancel = true;

        },
        onError: err => {
            console.log('OnError', err);
            this.showError = true;
        },
        onClick: (data, actions) => {
            console.log('onClick', data, actions);
            //this.resetStatus();
        },
    };
	
	console.log(this.payPalConfig);
}


}

