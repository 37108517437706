import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-categoty-menu',
  templateUrl: './categoty-menu.component.html',
  styleUrls: ['./categoty-menu.component.css']
})
export class CategotyMenuComponent implements OnInit {
  @Input() title?: string;
  constructor() { }

  ngOnInit() {
  }

}
