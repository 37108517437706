import { Component, OnInit } from '@angular/core';
import {AppSettingsService } from '../../services/app-settings.service';
@Component({
  selector: 'app-featured-in',
  templateUrl: './featured-in.component.html',
  styleUrls: ['./featured-in.component.css']
})
export class FeaturedInComponent implements OnInit {
  public baseURL: string = "";
  constructor(private appSettings: AppSettingsService) { 
    this.baseURL = this.appSettings.getBaseUrl();
  }

  ngOnInit() {
  }

}
