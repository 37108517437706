import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AppComponent } from '../../app.component';
// INTERFACES 
import { storeInterface } from './../../services/vendor.service';
import { userInterface,UserService } from './../../services/user.service';

// SERVICES
import { VendorService } from './../../services/vendor.service';
import { AppSettingsService } from '../../services/app-settings.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() public showHeader:boolean = true;
  @Output() menuClick = new EventEmitter();
  

  public cartData:any = null;
  cartCount: number = 0;
  private store: storeInterface = null;
  private user: userInterface = null;
  private showMenu: boolean = false;
  private vendorDetails: any;
  constructor(private router: Router ,
    private vendorService: VendorService,
    private cartService: CartService,
    private userService: UserService,
    private AppComponent: AppComponent,
    public appSettingsService: AppSettingsService) { 
    this
      .vendorService
      .storeDetails$
      .subscribe(mappedStore => {
        this.store = mappedStore;
      });

      this
      .userService.userDetails$
      .subscribe(mappedUser => {
        this.user = mappedUser;
        console.log('User Details',mappedUser);
      });
  }

  ngOnInit() {
    this.cartService.cart$.subscribe((res)=>{
      console.log('cartTotalQty',res);
      if(res){
        this.cartCount = res['cartTotalQty'];
      }
    });

    
    let storeName = this.appSettingsService.getStoreNameFromUrl();
    

      this.vendorService.getDetails(storeName).subscribe(mappedStore => {
     
       this.vendorDetails = mappedStore;
      
      console.log("mappedStore",this.vendorDetails);
     
    });
  }

  _logout(){
    localStorage.removeItem('userDetails');
    this.router.navigate(['']);
  }
  
  toggleMenu()
  {
     this.showMenu = true;
  }

  mobileToggleMenu()
  {
    console.log('toggle menu');

    if (window.innerWidth < 768) {
      this.menuClick.emit();
    }
    
  }

}
