import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-info',
  templateUrl: './category-info.component.html',
  styleUrls: ['./category-info.component.css']
})
export class CategoryInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
