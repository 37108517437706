import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  private apiUrl:string;
  private baseUrl:string;

  constructor() {
    let protocol = 'http://';
    if (location.href.indexOf('https') !== -1) {
      protocol = 'https://';
    }

    this.apiUrl = protocol + 'localhost/clara-multi-vendor/api';
    this.baseUrl = protocol +  'localhost/clara-multi-vendor/';
    if (location.href.indexOf('shopcsw') !== -1) {
      this.apiUrl = protocol + 'shopcsw.com/api/index.php';
      this.baseUrl = protocol + this.getStoreNameFromUrl() + '.shopcsw.com/';
    }
    
  }

  /**
   * getApiUrl
   */
  public getApiUrl() {
    return this.apiUrl;
  }
  public getBaseUrl() {
    return this.baseUrl;
  }

  /**
   * getStoreNameFromUrl
   */
  public getStoreNameFromUrl() {
    let url = location.href;
    url = url.replace('http://www.', '');
    url = url.replace('https://www.', '');
    url = url.replace('http://', '');
    url = url.replace('https://', '');

    let segmants = url.split('.');

    let storeName = segmants[0];
    if (storeName.indexOf('/') !== -1) {
      storeName = 'steve';
    }
    return storeName;
    
  }

/**
   * getCategoryGroupbyName
   */
  public getCategoryGroupbyName(name: string = ''){
    let groupName = '';
    if(name.includes('tops') || name.includes('tunics')){
      groupName = 'tops-tunics';
    }
    else if(name.includes('jackets')|| name.includes('cardigans')){
      groupName = 'jackets-cardigans';
    }
    else if(name.includes('tanks')|| name.includes('basics')){
      groupName = 'tanks-basics';
    }
    else if(name.includes('dresses')|| name.includes('jumpsuits')){
      groupName = 'dresses-jumpsuits';
    }
    else if(name.includes('bottoms')){
      groupName = 'bottoms';
    }
  }

  /**
   * getCategoryIdsByName
   */
  public getCategoryIdsByName(name: string = '') {
    let ids = '';
    switch (name) {
      case 'tops':
        ids = '1';
        break;
      case 'tunics':
        ids = '8';
        break;
      case 'tops-tunics':
        ids = '1,8';
        break;
      
      case 'jackets-cardigans':
        ids = '6,2';
        break;
      case 'tanks-basics':
        ids = '3,7';
        break;
      case 'dresses-jumpsuits':
        ids = '4,9';
      break;
      case 'bottoms':
        ids = '5';
      break;
      case '':
      
        break;
      default:
        break;
    }

    return ids;
  }

  /**
   * getCategoryIdsByName
   */
  public getVariantIdByName(name: string = '') {
    let ids = '';
    switch (name) {
      case 'extra-small-4-6':
        ids = '4';
        break;
      case 'small-8-10':
        ids = '5';
        break;
      case 'medium-12':
        ids = '6';
        break;
      case 'large-14':
        ids = '7';
      break;
      case 'extra-large-16':
        ids = '8';
      case '1X-18':
        ids = '9';
      break;
      case '':
      
        break;
      default:
        break;
    }

    return ids;
  }

  /**
   * getFallbackImage
   */
  public getFallbackImage(type) {
    let img: string = '';
    switch (type) {
      case 'compact':
        img = 'https://s3.amazonaws.com/inventory-clara/images/nophoto2.jpg';
        break;
    
      default:
        img = 'https://s3.amazonaws.com/inventory-clara/images/nophoto2.jpg';
        break;
    }

    return img;
  }

  /**
   * getFallbackImage
   */
  public getS3Path(type) {
    let img: string = '';
    switch (type) {
      case 'product':
        img = 'https://s3.amazonaws.com/inventory-clara/product-images/';
        break;
      case 'small':
        img = 'https://s3.amazonaws.com/inventory-clara/product-images/';
        break;
      default:
        img = 'https://s3.amazonaws.com/inventory-clara/product-images/';
        break;
    }

    return img;
  }

}
