import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: 'app/pages/home/home.module#HomeModule'
  },
  {
    path: 'product/:id',
    loadChildren: 'app/pages/product-view/product-view.module#ProductViewModule'
  },
  {
    path: 'collections/:category/:name',
    loadChildren: 'app/pages/collections/collections.module#CollectionsModule'
  },
  {
    path: '',
    loadChildren: 'app/pages/home/home.module#HomeModule',
    pathMatch: 'full'
  },
  {
    path: 'our-story',
    loadChildren: 'app/pages/our-story/our-story.module#OurStoryModule'
  },
  {
    path: 'fit-fabric',
    loadChildren: 'app/pages/fit-fabric/fit-fabric.module#FitFabricModule'
  },
  {
    path: 'press',
    loadChildren: 'app/pages/press/press.module#PressModule'
  },
  {
    path: 'fans',
    loadChildren: 'app/pages/fans/fans.module#FansModule'
  },
  {
    path: 'video-gallery',
    loadChildren: 'app/pages/video-gallery/video-gallery.module#VideoGalleryModule'
  },
  {
    path: 'discover',
    loadChildren: 'app/pages/discover/discover.module#DiscoverModule'
  },
  {
    path: 'login',
    loadChildren: 'app/pages/account/login/login.module#LoginModule'
  },
  {
    path: 'cart',
    loadChildren: 'app/pages/cart/cart.module#CartModule'
  },
  {
    path: 'checkout',
    loadChildren: 'app/pages/checkout/checkout.module#CheckoutModule'
  },
  {
    path: 'corporate-buyer-registration',
    loadChildren: 'app/pages/corporate-buyer-registration/corporate-buyer-registration.module#CorporateBuyerRegistrationModule'
  },
  {
    path: 'order-complete',
    loadChildren: 'app/pages/order-complete/order-complete.module#OrderCompleteModule'
  },
  {
    path: 'online-shop-customer-care',
    loadChildren: 'app/pages/online-shop-customer-care/online-shop-customer-care.module#OnlineShopCustomerCareModule'
  },
  {
    path: 'press-inquiries',
    loadChildren: 'app/pages/press-inquiries/press-inquiries.module#PressInquiriesModule'
  },
  {
    path: 'privacy-policy',
    loadChildren: 'app/pages/privacy-policy/privacy-policy.module#PrivacyPolicyModule'
  },
  {
    path: 'terms-of-service',
    loadChildren: 'app/pages/terms-of-service/terms-of-service.module#TermsOfServiceModule'
  },
  {
    path: 'returns-exchanges',
    loadChildren: 'app/pages/returns-exchanges/returns-exchanges.module#ReturnsExchangesModule'
  },
  {
    path: 'shipping-delivery',
    loadChildren: 'app/pages/shipping-delivery/shipping-delivery.module#ShippingDeliveryModule'
  },
  {
    path: 'error',
    loadChildren: 'app/pages/error/error.module#ErrorModule'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
