import { Component, OnInit,Input } from '@angular/core';
import { ProductService } from '../../../services/product.service';

import {forkJoin, of} from 'rxjs';  // change to new RxJS 6 import syntax
import { VendorService, storeInterface } from '../../../services/vendor.service';
import { mergeMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  @Input() public products:any = "";
  private vendor: storeInterface = null;
  public pro: any = "";
  public colors: any = "";
  public size: any = "";
  public images: any = "";
  constructor(
    private product: ProductService,
    private vendorService: VendorService
  ) { 

  }
  public showModal: boolean = false;
  ngOnInit() {

  }

  toggleModal(product_id:string){
    let ids = [product_id];

    this
      .vendorService
      .getDetails()
      .subscribe((mappedStore: storeInterface) => {
        this.vendor = mappedStore;
      });
    
    setTimeout(()=>{
      this.
        product.
        getVariants()
        .subscribe((mappedOptionsById) => {
          forkJoin( this.product.getProductDetails(ids, this.vendor.vendorId) )
            .pipe(
              mergeMap((response) => {
                if (response) {
                  let productData = response[0][product_id];
                  
                
                  if (!productData.images.length) {
                    return this.product.getProductImages(product_id).pipe(
                      map((resp: any) => {
                        let data = resp.data || []; 
                        let keys = Object.keys(productData.variants);
                        this.images = [];                          
                        for (const key of keys) {
                          let segments = key.split('-');
                          if (segments.length === 3) {
                            segments.pop();
                            let fn = segments.join('-');
                            for (const item of resp.data) {
                              if(item.file_name.indexOf(fn) !== -1) {
                                this.images.push({
                                  variant_id: item.product_variant_id,
                                  img_name : item.file_name
                                });
                              }
                            }
                          }
                        }

                        console.log(resp,  this.images);

                        this.pro = response[0][product_id];
                        this.colors = this.pro.colors;
                        this.size = this.pro.size;
                        // this.images = this.pro.images;
                        
                        this.pro.images = this.images;
                        return this.pro;
                      })
                    );
                  }

                  this.pro = response[0][product_id];
                  this.colors = this.pro.colors;
                  this.size = this.pro.size;
                  this.images = this.pro.images;

                  return of(response);
                }
              })
            )
            .subscribe((res)=>{
              console.log('Toggle Modal',res);
              // this.pro = res[0][product_id];
              // this.colors = this.pro.colors;
              // this.size = this.pro.size;
              // this.images = this.pro.images;

            });
          this.showModal = true;
        });
      
  },100);
  }
  private onCloseHandled(){

    this.showModal= false;
    
  }
}
