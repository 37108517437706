import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { CartService } from '../../../services/cart.service';
import { ProductSliderComponent } from '../../product-slider/product-slider.component';
import { ProductInterface } from '../../../services/product.service';
import { AppSettingsService } from '../../../services/app-settings.service';
@Component({
  selector: 'app-product-quick-description',
  templateUrl: './product-quick-description.component.html',
  styleUrls: ['./product-quick-description.component.css']
})
export class ProductQuickDescriptionComponent implements OnInit {
  @ViewChild(ProductSliderComponent) slider: ProductSliderComponent;
  @Input() public product: ProductInterface;
  private size: Array<any> = [];
  private colors: Array<any> = [];

  public selColor:string;
  public selSize:string;
  public proPrice: number = 0;
  private productQuantity: number;
  public imgType: any;
  public img_name: string = "";

  allCart: any;
  public quantity: number = 1;
  public cartModal: boolean = false;
  public cartData:any;
  constructor(private cartService: CartService,
    private appSettingsService: AppSettingsService,
  ) { }

  ngOnInit() {
    
    if (window.innerWidth < 768 ) {
      this.imgType = "small";
     } else {
       this.imgType = "medium";
     }
  }
 ngOnChanges(changes: any) {
  
  if (this.product) {
    this.size = this.product.size;
    this.colors = this.product.colors;
    
    let varSku = this.product.featuredVariant;
    console.log('SKU:::',varSku);
    let splitSku = varSku.split("-");
    this.selColor = splitSku[1];
    this.selSize = splitSku[2];
    console.log('products',this.product);
    console.log('selSize:::',this.selSize);
    this.proPrice        = this.product.variants[varSku].variant_price;
    this.productQuantity = this.product.variants[varSku].quantity;
   
  }  
 }

 onColorChange(){
  let availble_keys = Object.keys(this.product.variants);
  this.size = [];
  for (const s of this.product.size) {
    let sku = this.product.sku+"-"+this.selColor+"-"+s.shortCode;
  
    if (availble_keys.indexOf(sku) !== -1) {
      this.size.push(s);
    }
  }
  
  
  let sk = this.product.sku+"-"+this.selColor+"-"+this.selSize;
  console.log('Sku',sk,this.selColor, this.selSize);
  if (availble_keys.indexOf(sk) === -1) {
    this.selSize = this.size[0].shortCode;
    sk = this.product.sku+"-"+this.selColor+"-"+this.selSize;      
  }
  
  this.proPrice = this.product.variants[sk].variant_price;
}

onSizeChange(){

  let availble_keys = Object.keys(this.product.variants);
  this.colors = [];
  for (const c of this.product.colors) {
    let sku = this.product.sku + "-" + c.value + "-" + this.selSize;
    if (availble_keys.indexOf(sku) !== -1) {
      this.colors.push(c);
    }
    
  }
  
  let sk= this.product.sku+"-"+this.selColor+"-"+this.selSize;
  if (availble_keys.indexOf(sk) === -1) {
    this.selColor = this.colors[0].value;
    sk = this.product.sku+"-"+this.selColor+"-"+this.selSize;      
  }
  
  this.proPrice = this.product.variants[sk].variant_price;
}

 addToCart(){

  let myForm = {color:this.selColor,size:this.selSize,qty:this.quantity};    
  const key = this.product.sku + '-' + myForm.color + '-' + myForm.size;

  const orderedQty = this.cartService.getOrderedQuantity(this.product, key) || 0;

  this.productQuantity = this.product.variants[key].quantity;

  if(this.productQuantity < (this.quantity+orderedQty)){
    alert('This item is temporarily out of stock');
    return false;
  }

  if (!isNaN(myForm.qty) && myForm.qty) {
    myForm.qty = Math.round(myForm.qty);
    this.cartService.insert(this.product, key, myForm.qty);
  } else {
    this.cartService.remove(this.product, key);
  }

  this.loadCartData();
  this.cartModal= true;

}
 loadCartData(){
  this.cartService.cart$.subscribe((res)=>{
    this.allCart = res['cart'];
    this.cartData = res['cartItems'];
    console.log('cartData',this.cartData);
    });
 }
  onCloseHandled(){
  this.cartModal= false;
  
  //this.slider.onCloseHandled();
}
removeCartItem(parent_id:number,sku:string){
  let a: any = {id:parent_id};
  this.cartService.remove(a,sku);
  console.log('Remove Cart',this.cartData);
}

getImg(variant_id, parent_id) {
    
  if (!this.allCart) {
    return '';
  }

  let imgs: any = this.allCart[parent_id].images;
  for(const i of imgs) {
    if( i.variant_id != 0 )
    {
        let splitImg = i.img_name.split('.');
        let img_name: string = splitImg[0] +'_'+'xtra_small'+'.'+splitImg[1];
        return this.appSettingsService.getS3Path('product') +  parent_id + "/" + img_name;
    }
  }
}

  continueShopping(){
    this.onCloseHandled();
  }
}
