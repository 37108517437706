import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-last-viewed',
  templateUrl: './product-last-viewed.component.html',
  styleUrls: ['./product-last-viewed.component.css']
})
export class ProductLastViewedComponent implements OnInit {
  public products: any = [];
  public imgType : any;
  constructor() { }

  ngOnInit() {
    let storage  = localStorage.getItem('last-viewed');
    if (window.innerWidth < 768 ) {
      this.imgType = "small";
     } else {
       this.imgType = "medium";
     }
    if(storage !== null)
    {
      let json = JSON.parse(storage);
      this.products = json.slice(-3);
    }
    console.log('Last Viewed',this.products);
  }

}
