import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { S3Pipe } from './s3.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [S3Pipe],
  exports: [S3Pipe]
})
export class PipesModule { }
