import { Component, OnInit,Output,EventEmitter,Input } from '@angular/core';
import {FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
@Component({
  selector: 'app-shipping-method',
  templateUrl: './shipping-method.component.html',
  styleUrls: ['./shipping-method.component.css']
})
export class ShippingMethodComponent implements OnInit {
  @Input() public prevForm = new EventEmitter<any>();
  @Output() public nextStep = new EventEmitter<string>();
  @Output() public formValue = new EventEmitter<any>();
  @Output() public shipCost = new EventEmitter<string>();
  @Input() public shippingCharges = new EventEmitter<string>();
  public shipVariable: ShipInfo = {ship_type:'',ftype:'shipping'};
  public shippingForm: FormGroup;
  public shipLabel:string = '';
  // public shippingCharges: any;
  public shippingFormSubmitted: boolean = false;
  constructor() { }

  ngOnInit() {
    console.log('Prev Form',this.prevForm);
    this.shippingForm = new FormGroup({
      ship_type: new FormControl(this.shipVariable.ship_type,[Validators.required]),
      ftype: new FormControl('shipping'),
      label: new FormControl(this.shipLabel),
    });
    
  }
  goNext(str:string)
  {
    this.shippingFormSubmitted = true;
    console.log('Shipping Form',this.shippingForm.value);
    if(this.shippingForm.valid)
    {
      this.shippingForm.value.label = this.shipLabel;
      this.formValue.emit(this.shippingForm.value);
      this.nextStep.emit(str);
    }
  }
  goPrev(str:string)
  {
    this.nextStep.emit(str);
  }

  selectShipping(event)
  {
    
    let val:any    = {text:event.target.dataset.text,value:event.target.dataset.value};
    this.shipLabel = event.target.dataset.text;
    //console.log('Ship Value',event.target.dataset);
    this.shipCost.emit(val);
  }

}
export interface ShipInfo {
  ship_type:string,
  ftype:string;
};