import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
@Component({
  selector: 'app-cart-count',
  templateUrl: './cart-count.component.html',
  styleUrls: ['./cart-count.component.css']
})
export class CartCountComponent implements OnInit {
  public cartCount:any;
  constructor(private cartService: CartService) { }

  ngOnInit() {
    this.cartCount = this.cartService.contents();
    
  }

}
