import { Component, OnInit,Input,NgModule } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {ProductService, ProductInterface} from '../../../services/product.service';
import { CartService } from '../../../services/cart.service';
import { AppSettingsService } from '../../../services/app-settings.service';
@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.css']
})
export class ProductDescriptionComponent implements OnInit {

  @Input() public product: ProductInterface

  selectedImg: string = "";
  public display='none';
  public proPrice: number = 0;
  public selColor:string;
  public selSize:string;
  public quantity: number = 1;
  public cartData:any;
  public cartQuantity:number = 0;
  public allCart: any;
  public showModal: boolean  = false;
  private showAddToBag: boolean = true;
  private productQuantity: number;
  private size: any;
  private colors: any;
  public imgType: any;
  public img_name: string = "";
  constructor(private productService:ProductService,
     private route: ActivatedRoute,
     private cartService: CartService,
     private appSettingsService: AppSettingsService,
      private router: Router) { }

  ngOnChanges(changes:any)
  {
    if (this.product) {
      this.size = this.product.size;
      
      this.colors = this.product.colors;
      
      let varSku = this.product.featuredVariant;
      console.log('SKU:::',varSku);
      let splitSku = varSku.split("-");
      this.selColor = splitSku[1];
      this.selSize = splitSku[2];
      console.log('products',this.product);
      //console.log('selSize:::',this.selSize);
      //console.log('selColor:::',this.selColor);
      this.proPrice        = this.product.variants[varSku].variant_price;
      this.productQuantity = this.product.variants[varSku].quantity;
      
      console.log(this.productQuantity);

      if(this.product.variants[varSku].quantity == 0){
        this.showAddToBag = false;
      }
    }    
  }
  
  ngOnInit() {
    if (window.innerWidth < 768 ) {
      this.imgType = "small";
     } else {
       this.imgType = "medium";
     }
  }

  onColorChange(){
    let availble_keys = Object.keys(this.product.variants);
    this.size = [];
    for (const s of this.product.size) {
      let sku = this.product.sku+"-"+this.selColor+"-"+s.shortCode;
    
      if (availble_keys.indexOf(sku) !== -1) {
        this.size.push(s);
      }
    }
    
    
    let sk = this.product.sku+"-"+this.selColor+"-"+this.selSize;
    console.log('Sku',sk,this.selColor, this.selSize);
    if (availble_keys.indexOf(sk) === -1) {
      this.selSize = this.size[0].shortCode;
      sk = this.product.sku+"-"+this.selColor+"-"+this.selSize;      
    }
    
    this.proPrice = this.product.variants[sk].variant_price;
  }

  onSizeChange(){

    let availble_keys = Object.keys(this.product.variants);
    this.colors = [];
    for (const c of this.product.colors) {
      let sku = this.product.sku + "-" + c.value + "-" + this.selSize;
      if (availble_keys.indexOf(sku) !== -1) {
        this.colors.push(c);
      }
      
    }
    
    let sk= this.product.sku+"-"+this.selColor+"-"+this.selSize;
    if (availble_keys.indexOf(sk) === -1) {
      this.selColor = this.colors[0].value;
      sk = this.product.sku+"-"+this.selColor+"-"+this.selSize;      
    }
    
    this.proPrice = this.product.variants[sk].variant_price;
  }

  addToCart(){

    let myForm = {color:this.selColor,size:this.selSize,qty:this.quantity};    
    const key = this.product.sku + '-' + myForm.color + '-' + myForm.size;

    const orderedQty = this.cartService.getOrderedQuantity(this.product, key) || 0;
    this.productQuantity = this.product.variants[key].quantity;
    
    if(this.productQuantity < (this.quantity+orderedQty)){
      alert('This item is temporarily out of stock');
      return false;
    }

    if (!isNaN(myForm.qty) && myForm.qty) {
      myForm.qty = Math.round(myForm.qty);
      this.cartService.insert(this.product, key, myForm.qty);
    } else {
      this.cartService.remove(this.product, key);
    }

    this.loadCartData();
    this.showModal= true;

  }

  loadCartData(){
    this.cartService.cart$.subscribe((res)=>{
      this.allCart = res['cart'];
      this.cartData = res['cartItems'];
      console.log('Cart Data', this.cartData);
  });
  }


  getImg(variant_id, parent_id) {
    console.log("called getImg")
    if (!this.allCart) {
      return '';
    }
    
    let imgs: any = this.allCart[parent_id].images;
    for(const i of imgs) {
      if( i.variant_id != 0 )
      {
        
        let splitImg = i.img_name.split('.');
        let img_name: string = splitImg[0] +'_'+'xtra_small'+'.'+splitImg[1];
        console.log(this.appSettingsService.getS3Path('product') +  parent_id + "/" + img_name);
        return this.appSettingsService.getS3Path('product') +  parent_id + "/" + img_name;
      }
    }
  }

  private onCloseHandled(){
    this.showModal= false;
  }
  removeCartItem(parent_id:number,sku:string){
    let a: any = {id:parent_id};
    this.cartService.remove(a,sku);
    console.log('Remove Cart',this.cartData);
  }
}
