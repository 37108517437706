import { Component, OnInit,Output,EventEmitter,Input } from '@angular/core';
import {FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import { CouponService } from '../../../services/coupon.service';
import { CheckoutInterface } from '../../../pages/checkout/checkout.component';
@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnInit {
  @Output() nextStep = new EventEmitter<string>();
  @Input() statesByCountry:any;
  @Input() checkout:CheckoutInterface;
  

  private states: Array<any> = [];
  public contactForm: FormGroup;
  public shippingForm: FormGroup;

  flag = false;

  contactFormSubmitted:boolean = false;
  shippingFormSubmitted:boolean = false;
  // public shippingAddress: AddressInterface = {firstName:'',lastName:'',address1:'',address2:'',city:'',state:'',country:'US',zipcode:null,phone:null,type:'contact'};
  constructor(
    private couponService: CouponService

  ) { 
    
  }

  ngOnInit() {
    
  }

  ngOnChanges(changes) {
    console.log('changes', changes);
    
    if (changes.checkout && changes.checkout.currentValue && changes.checkout.currentValue.shippingAddrees && changes.checkout.currentValue.shippingAddrees.country) {
      if (this.statesByCountry[this.checkout.shippingAddrees.country]) {
        this.states = this.statesByCountry[this.checkout.shippingAddrees.country];
      }      
      this.populateForms();      
    }
  }

  populateForms() {
    // Contact form
    this.contactForm = new FormGroup({
      email: new FormControl(this.checkout.contactInfo.email,[Validators.required,Validators.email])
    });

    // Shipping Form
    this.shippingForm = new FormGroup({
      firstName: new FormControl(this.checkout.shippingAddrees.firstName,[Validators.required]),
      lastName: new FormControl(this.checkout.shippingAddrees.lastName,[Validators.required]),
      address1: new FormControl(this.checkout.shippingAddrees.address1,[Validators.required]),
      address2: new FormControl(this.checkout.shippingAddrees.address2,[]),
      city: new FormControl(this.checkout.shippingAddrees.city,[Validators.required]),
      state: new FormControl(this.checkout.shippingAddrees.state,[Validators.required]),
      country: new FormControl(this.checkout.shippingAddrees.country,[]),
      zipcode: new FormControl(this.checkout.shippingAddrees.zipcode,[Validators.required,CustomValidators.number]),
      // phone: new FormControl(this.shippingInfo.phone,[Validators.required,CustomValidators.number,Validators.maxLength(15),Validators.minLength(10)]),
      phone: new FormControl(this.checkout.shippingAddrees.phone,[Validators.required,CustomValidators.number]),
      type: new FormControl(this.checkout.shippingAddrees.type)
    });
  }

  goNext(str:string)
  {
    console.log('Form',this.contactForm.value, this.shippingForm.value);
    
    this.contactFormSubmitted = true;
    this.shippingFormSubmitted = true;
    if(this.contactForm.invalid && this.shippingForm.invalid){
       return false;
    }
  
    if(this.contactForm.valid && this.shippingForm.valid)
    {
      // update checkout data
      this.checkout.contactInfo = this.contactForm.value;
      this.checkout.shippingAddrees = this.shippingForm.value;
      this.checkout.shippingAddrees.type = 'S';

      // this.formValue.emit({contactInfo: this.contactForm.value, shippingInfo: this.shippingForm.value});

      this.nextStep.emit(str);
    }

  }

  goPrev(str:string){
    this.nextStep.emit(str);
  }


  stateChange(event){
    // this.formValue.emit(this.contactForm.value);
  }

  countryChange(event) {
    console.log(event.target.value);
    this.states = [];
    if (this.statesByCountry[event.target.value]) {
      this.states = this.statesByCountry[event.target.value];
    }
  }

  

}

export interface ContactInfoInterface {
  email: string;
};

export interface AddressInterface {
  firstName: string,
  lastName: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  country: string,
  zipcode: string,
  phone: string;
  type?: string;
};

