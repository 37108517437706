import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject,of } from 'rxjs';
import { map,mergeMap,catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private http: HttpClient) { }

  public applyCoupon(form:string){
    let params: HttpParams = new HttpParams();
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: form
    };
    return this.
            http.
            post('/coupons/applyCoupon',httpOptions);
    }

    public getStates(): Observable<HttpResponse<any>> {
      const httpParams: HttpParams = new HttpParams();
      return this
      .http
      .get<HttpResponse<any>>('/coupons/list_states/', {params: httpParams})
      .pipe(map(resp => {
        let httpResponse: any = resp;
        return httpResponse.states;
      }));
    } 
}
