import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject,of } from 'rxjs';
import { map,mergeMap,catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userDetailsSource: BehaviorSubject<userInterface> = new BehaviorSubject(null);
  // observable stream
  public userDetails$ = this.userDetailsSource.asObservable();
  constructor(private http: HttpClient) { }

  public doLogin(form: any){
    let params: HttpParams = new HttpParams();
    
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: form
    };
    return this.
            http.
            post('/users/login',httpOptions);
  }

  public getShippingCharges():Observable<any>{
    
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };

    return this
    .http
    .post('/shipping_charge/list/',httpOptions)
    .pipe(map(resp => {
      console.log('Shipping Resp:::', resp);
      let httpResponse: any = resp;
      return httpResponse.items;
    }));
    
  }

  public getUserDetails():Observable<any> {

    // let localItem = localStorage.getItem('userDetails');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };

    return this.
          http.
          post('/users/login',httpOptions).pipe(map(res=>{

            let localItem: userInterface = null;
            localItem = JSON.parse(localStorage.getItem('userDetails'));
            console.log('Local Item',localItem);
            this.userDetailsSource.next(localItem);
            return localItem;
          }));
  }

}

export interface userInterface{
  userId:number;
  firstName:string;
  lastName:string;
  role:string
}