import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-discover-menu',
  templateUrl: './discover-menu.component.html',
  styleUrls: ['./discover-menu.component.css']
})
export class DiscoverMenuComponent implements OnInit {
  @Input() title?: string;
  constructor() { }

  ngOnInit() {
  }

}
