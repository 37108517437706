import { Component, OnInit,Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {AppSettingsService} from '../../../services/app-settings.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  public catLink: string="";
  public segment: any = {category:'',sub_category:''};
  public baseURL: string = "";

  @Input() public details:any = [];
  constructor(private appSettings: AppSettingsService, private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    console.log('Breadcrumb',this.route.params);
    
    this.baseURL = this.appSettings.getBaseUrl();


    this.route.params.subscribe(params => {
      this.catLink = params.name.replace(' & ','-');
      //this.segment.category = params.category;
      params.sub_category
      
    });

    
    
  }


   
 public getCategoryGroupbyName(name: string = ''){
   let groupName = '';
   if(name.includes('tops') || name.includes('tunics')){
     groupName = 'tops-tunics';
   }
   else if(name.includes('jackets')|| name.includes('cardigans')){
     groupName = 'jackets-cardigans';
   }
   else if(name.includes('tanks')|| name.includes('basics')){
     groupName = 'tanks-basics';
   }
   else if(name.includes('dresses')|| name.includes('jumpsuits')){
     groupName = 'dresses-jumpsuits';
   }
   else if(name.includes('bottoms')){
     groupName = 'bottoms';
   }
 }


}
