import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

// rxjs
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from "rxjs/operators";

import { AppSettingsService } from './app-settings.service';
@Injectable({
  providedIn: 'root'
})
export class VendorService {
  // source for observable
  private storeDetailsSource: BehaviorSubject<storeInterface> = new BehaviorSubject(null);
  // observable stream
  public storeDetails$ = this.storeDetailsSource.asObservable();

  constructor(private http: HttpClient, private appSettingsService: AppSettingsService) { }

  /**
   * getInfo
   */
  public getDetails(storeName: string = '', force: boolean = false) {
    
    if (storeName === '') {
      storeName = this.appSettingsService.getStoreNameFromUrl();
    }
    
    // if available , return from observable
    if (!force && this.storeDetailsSource.value) {
      return of(this.storeDetailsSource.value); 
    }

    return this
            .http
            .get('/stores/' + storeName)
            .pipe(map(resp => {
              console.log('map:::', resp);
              let response: any = resp,
                  storeDetails: storeInterface = null;
              if(response.status 
                  && response.status === 'success' 
                  && response.data 
                ) {

                storeDetails = {
                  storeName: response.data.store_name,
                  boutiqueName: response.data.boutique_name,
                  logo: response.data.logo,
                  vendorId: response.data.user_id,
                  vendorName: response.data.name,
                  email: response.data.email,
                  phoneNumber: response.data.phone_number,
                  shippingCharge:response.data.shipping_rate,
                  fileName:response.data.file_name,
                  refundExchange:response.data.refund_exchange || '',
                  address: {
                    address1: response.data.address1,
                    address2: response.data.address2,
                    city: response.data.city,
                    state: response.data.state,
                    zipcode: response.data.zip,
                    country: response.data.country,
                  },
                  paypal: null,
                  categories: response.categories,
                  variants: response.variants
                };

                // set paypal info
                if (response.data.paypal_credentials && response.data.paypal_credentials.length) {

                    let paymentInfo = JSON.parse(response.data.paypal_credentials);
                    storeDetails.paypal = {
                      enabled: true,
                      clientId: paymentInfo.clientid,
                      mode: response.data.paypal_payment_mode
                    };
                } 
                else 
                {
                  storeDetails.paypal = {enabled: false};
                }

                // set authorize info
                if (response.data.is_authorize_enabled) {

                  if (response.data.is_authorize_enabled == '1') {
                    storeDetails.isAuthorizePaymentEnabled = true;
                  } 
                  else 
                  {
                    storeDetails.isAuthorizePaymentEnabled = false;
                  }

                }
                console.log('storeDetails', storeDetails);
                this.storeDetailsSource.next(storeDetails);
              }
              return storeDetails;
          })
      );
     

  }


}

export interface storeInterface {
  storeName: string,
  logo: string,
  vendorId: string,
  vendorName: string,
  boutiqueName: string,
  email: string,
  phoneNumber: string,
  shippingCharge?:string,
  fileName?:string,
  refundExchange?:string;
  address: {
    address1: string,
    address2: string,
    city: string,
    state: string,
    country: string,
    zipcode: string
  },
  paypal?: {
    enabled?: boolean,
    signature?: string,
    mode?: string,
    clientId?: string
  },
  isAuthorizePaymentEnabled?: boolean,
  categories?: Array<any>,
  variants?: Array<any>
}
