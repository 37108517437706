import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { CategoryInfoComponent } from './category-info/category-info.component';
import { ProductSliderComponent } from './product-slider/product-slider.component';
import { CartCountComponent } from './cart-count/cart-count.component';
import { FooterInfoComponent } from './footer-info/footer-info.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { Routes, RouterModule } from '@angular/router';
import { PipesModule } from '../pipes/pipes.module';
/*Product Page Import*/
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbComponent } from './product/breadcrumb/breadcrumb.component';
import { ProductGalleryComponent } from './product/product-gallery/product-gallery.component';
import { ProductDescriptionComponent } from './product/product-description/product-description.component';
import { ProductReviewComponent } from './product/product-review/product-review.component';
import { ProductLastViewedComponent } from './product/product-last-viewed/product-last-viewed.component';
import { BannerComponent } from './collections/banner/banner.component';
import { ProductListComponent } from './collections/product-list/product-list.component';
import { SideFilterComponent } from './collections/side-filter/side-filter.component';
import { ContactInformationComponent } from './checkout/contact-information/contact-information.component';
import { ShippingMethodComponent } from './checkout/shipping-method/shipping-method.component';
import { PaymentMethodComponent } from './checkout/payment-method/payment-method.component';
import { ProductImagesComponent } from './quick-view/product-images/product-images.component';
import { ProductQuickDescriptionComponent } from './quick-view/product-quick-description/product-quick-description.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { FeaturedInComponent } from './featured-in/featured-in.component';
import { CategotyMenuComponent } from './categoty-menu/categoty-menu.component';
import { DiscoverMenuComponent } from './discover-menu/discover-menu.component';
import { ImgFallbackDirective } from './img-fallback.directive';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsServiceComponent } from './terms-service/terms-service.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DragScrollModule,
    MDBBootstrapModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPayPalModule,
    PipesModule
  ],
  exports: [
    SideMenuComponent,
    CategoryInfoComponent,
    ProductSliderComponent,
    CartCountComponent,
    FooterInfoComponent,
    BreadcrumbComponent,
    ProductGalleryComponent,
    ProductDescriptionComponent,
    ProductReviewComponent,
    ProductLastViewedComponent,
    BannerComponent,
    ProductListComponent,
    SideFilterComponent,
    ContactInformationComponent,
    ShippingMethodComponent,
    PaymentMethodComponent,
    ProductQuickDescriptionComponent,
    ProductImagesComponent,
    FeaturedInComponent,
    ImgFallbackDirective,
    RefundPolicyComponent,
    PrivacyPolicyComponent,
    TermsServiceComponent

  ],  
  declarations: [SideMenuComponent, CategoryInfoComponent, ProductSliderComponent, CartCountComponent, FooterInfoComponent, BreadcrumbComponent, ProductGalleryComponent, ProductDescriptionComponent, ProductReviewComponent, ProductLastViewedComponent, BannerComponent, ProductListComponent, SideFilterComponent, ContactInformationComponent, ShippingMethodComponent, PaymentMethodComponent, ProductImagesComponent, ProductQuickDescriptionComponent,FeaturedInComponent, CategotyMenuComponent, DiscoverMenuComponent, ImgFallbackDirective, RefundPolicyComponent, PrivacyPolicyComponent, TermsServiceComponent]
})
export class SharedModule {
  
 }
