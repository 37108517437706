import { Component, OnInit,Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-side-filter',
  templateUrl: './side-filter.component.html',
  styleUrls: ['./side-filter.component.css']
})
export class SideFilterComponent implements OnInit {
  @Input() public products: any;
  @Output() sortedProducts = new EventEmitter<any>();

  private collectionMenuFlag: boolean = false;
  constructor() { }

  ngOnInit() {
    console.log('Sort products',this.products);
  }
  sortFilter(event){
    let e = event.target.value;
    if(e === 'title-ascending' || e === 'title-descending'){
      this.sortByTitle(e);
    }
    else if(e === 'price-ascending' || e === 'price-descending'){
      this.sortByPrice(e);
    }
    else if(e === 'created-ascending' || e === 'created-descending'){
      this.sortByDate(e);
    }
    this.sortedProducts.emit(this.products);
    console.log('Sort products',this.products);
  }

  sortByTitle(type:string){
    this.products.sort(function(a,b){
      var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
      if (nameA > nameB) //sort string ascending
        return 1 
      if (nameA < nameB)
        return -1
      return 0 //default return value (no sorting)
    });
  }

  sortByPrice(type:string){
    this.products.sort(function(a, b) {
      if(type === 'price-ascending')
        return parseFloat(a.price) - parseFloat(b.price);
      else
        return parseFloat(b.price) - parseFloat(a.price);
    });
  }

  sortByDate(type:string){
    this.products.sort(function(a, b) {
      var dateA: any = new Date(a.created_time), dateB: any=new Date(b.created_time);
      if(type === 'created-ascending')
        return dateA - dateB;
      else
        return dateB - dateA;
    });
  }

  handleCollectionMenu() {
    this.collectionMenuFlag = !this.collectionMenuFlag;
  }
  
}
