import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject,of } from 'rxjs';
import { map,mergeMap,catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cart: CartInterface = {};
  private cartTotal = 0;
  private totalItems = 0;
  private cartTotalQty:number = 0;
  private cartItems: Array<VariantInterface>;
  // source for observable
  private cartSource: BehaviorSubject<CartSourceInterface> = new BehaviorSubject(null);
  // observable stream
  public cart$ = this.cartSource.asObservable();


  constructor(private http: HttpClient) { }

  public insert(product: ProductInterface, variantSKU: string, qty: number) {
    
    if (this.cart[product.id]) {
      
      if (this.cart[product.id].variants && this.cart[product.id].variants[variantSKU]) { // Update
        
        if(this.cart[product.id].variants[variantSKU].orderedQuantity)
          this.cart[product.id].variants[variantSKU].orderedQuantity += qty;
        else
          this.cart[product.id].variants[variantSKU].orderedQuantity = qty;
      } else { // Add
        if (this.cart[product.id].variants) {
          this.cart[product.id].variants[variantSKU].orderedQuantity = qty;
        }
      }
    } else { // Add
      this.cart[product.id] = product;
      if (this.cart[product.id].variants && this.cart[product.id].variants[variantSKU]) {
        this.cart[product.id].variants[variantSKU].orderedQuantity = qty;
      }
    }

    localStorage.setItem('cart-data', JSON.stringify(this.cart));
    
    // trigger cartSource
    this.updateCartSouce();
  }

  public getOrderedQuantity(product: ProductInterface, variantSKU: string){
    
    if (this.cart[product.id] 
         && this.cart[product.id].variants 
         && this.cart[product.id].variants[variantSKU]
        ) {
          return this.cart[product.id].variants[variantSKU].orderedQuantity;      
         }
         
    return 0;
  }

  public updateFront(product:any,index:number,qty:number){
    console.log('Fron Cart',this.cart);
  }

  public update(product: ProductInterface, variantSKU: string, qty: number) {
    if (this.cart[product.id]) {
      if (this.cart[product.id].variants && this.cart[product.id].variants[variantSKU]) { // Update
        this.cart[product.id].variants[variantSKU].orderedQuantity = qty;
      }
    } else { // Add
      this.cart[product.id] = product;
      if (this.cart[product.id].variants && this.cart[product.id].variants[variantSKU]) {
        this.cart[product.id].variants[variantSKU].orderedQuantity = qty;
      }
    }

    localStorage.setItem('cart-data', JSON.stringify(this.cart));

    // trigger cartSource
    this.updateCartSouce();
  }

  public remove(product: ProductInterface, variantSKU: string) {
    if (!this.cart[product.id]) {
      return;
    }

    if (this.cart[product.id].variants && this.cart[product.id].variants[variantSKU]) {
      this.cart[product.id].variants[variantSKU].orderedQuantity = 0;
    }

    if (this.cart[product.id].variants) {
      let found = false;
      for (const vsku in this.cart[product.id].variants) {
        if (this.cart[product.id].variants.hasOwnProperty(vsku)) {
          if (this.cart[product.id].variants[vsku].orderedQuantity) {
            found = true;
          }
        }
      }

      if (!found) {
        delete this.cart[product.id];
      }
    }

    localStorage.setItem('cart-data', JSON.stringify(this.cart));

    // trigger cartSource
    this.updateCartSouce();
  }

  public contents() {
    this.updateCartSouce();
    return this.cartItems;
  }

  public updateCartSouce() {

    // update cart
    this.updateCartData();

    const cartSouceData: CartSourceInterface =  {
      cart: this.cart,
      cartItems: this.cartItems,
      cartTotal: this.cartTotal,
      totalItems: this.totalItems,
      cartTotalQty: this.cartTotalQty
    };

    console.log('cartSouceData: ', cartSouceData);
  
    // Put the object into storage
    
    // Retrieve the object from storage
    // var retrievedObject = localStorage.getItem('testObject');

    // console.log('retrievedObject: ', JSON.parse(retrievedObject));
    this.cartSource.next(cartSouceData);
  }

  private updateCartData() {
    this.cartTotal = 0;
    this.totalItems = 0;
    this.cartItems = [];
    this.cartTotalQty = 0;
    const storage = [];
    
    if (!Object.keys(this.cart).length && localStorage.getItem('cart-data')) {
      this.cart = JSON.parse(localStorage.getItem('cart-data'));
      console.log('cdata', this.cart);
    }

    for (const productId in this.cart) {
      if (this.cart.hasOwnProperty(productId)) {
        const product = this.cart[productId];
        for (const variantSKU in product.variants) {
          if (product.variants.hasOwnProperty(variantSKU)) {
            const variant = product.variants[variantSKU];
            if (variant.orderedQuantity) {
              this.cartTotal += (variant.orderedQuantity * variant.variant_price);
              this.cartItems.push(variant);
              this.totalItems++;
              this.cartTotalQty = Number(this.cartTotalQty) + Number(variant.orderedQuantity);              
            }
          }
        }
      }
    }
  }
  public resetCartData(): Promise<boolean>
  {    
    return new Promise((resolve, reject)=>{
      this.cart = {};
      const cartSouceData : CartSourceInterface = {
        cart: null,
        cartItems: [],
        cartTotal: 0,
        totalItems: 0,
        cartTotalQty:0
      };
      this.cartSource.next(cartSouceData);
      localStorage.removeItem('cart-data');
      console.log('Reset',cartSouceData);
      resolve(true);
    });
  }

  public  calculateTax(taxData: any){
    
    //let params: HttpParams = new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };

    return this.
            http.
            post('/sales_order/calculate_tax',taxData,httpOptions);
  }

}



export interface CartInterface {
  [key: string]: ProductInterface;
}

export interface CartSourceInterface {
  cart: CartInterface;
  cartTotal: number;
  totalItems: number;
  cartTotalQty:number;
  cartItems: Array<VariantInterface>;
}

export interface VariantInterface {
  id: string;
  sku: string;
  name: string;
  price: number;
  variant_price:number;
  quantity: number;
  available: boolean;
  orderedQuantity?: number;
}

export interface ProductInterface {
  id: string;
  sku: string;
  name: string;
  price: number;
  description: string;
  available: boolean;
  featuredImage: string;
  images: Array<ProductImageInterface>;
  options: Array<string>;
  variants: {[key: string]: VariantInterface};
  colors: Array<string>;
  size: Array<string>;
  video:string;
}

export interface ProductImageInterface
{
  variant_id:number;
  img_name:string;
}

export interface OptionInterface {
  id: string;
  name: string;
  position: number;
  selectedValue?: OptionValuInterface;
  values?: Array<OptionValuInterface>;
}

export interface OptionValuInterface {
  id: string;
  name: string;
  shortCode: string;
}

export interface VariantInterface {
  id: string;
  sku: string;
  name: string;
  price: number;
  quantity: number;
  available: boolean;
  options: Array<OptionInterface>;
  orderedQuantity?: number;
}