import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 's3'
})
export class S3Pipe implements PipeTransform {

  transform(value: string, ...args) {
    console.log('VALUE', value);
    let s3Url = 'https://s3.amazonaws.com/inventory-clara/';
    console.log('PIPES', args);
    let type = Array.isArray(args) && args.length ? args[0]: 'images';
    let id = Array.isArray(args) && args.length > 1 ? args[1]: '1';
    let size = Array.isArray(args) && args.length > 2 ? args[2]: null;

    
    switch (type) {
      case 'images':
        s3Url += 'images/';
        break;
      case 'videos':
        s3Url += 'videos/';
        break;
      case 'product':
        s3Url += 'product-images/' + id + '/';
        break;
      case 'vendor':
        s3Url += 'vendors/' + id + '/';
        break;
      default:
        s3Url += 'images/';
        break;
    }

    if (size) {
      let splitImg = value.split('.');
      let actualname = (size === 'thumb')?'xtra_small': size;
      value =  splitImg[0] +'_'+actualname+'.'+splitImg[1];
    }

    s3Url += value;
    
    console.log('s3Url', s3Url);
    return s3Url;
  }

}
