import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { VendorService, storeInterface } from '../../services/vendor.service';
import { AppSettingsService } from '../../services/app-settings.service';

@Component({
  selector: 'app-refund-policy',
  templateUrl: './refund-policy.component.html',
  styleUrls: ['./refund-policy.component.css']
})
export class RefundPolicyComponent implements OnInit {

  private vendorDetails: storeInterface;  
    
  constructor(public AppComponent: AppComponent,
  	public vendorService: VendorService,
  	public appSettingsService: AppSettingsService) { }

    ngOnInit() {
      // console.log('onInit', this.appSettingsService.getStoreNameFromUrl(), this.vendorService); 
   
   // update cart if localstorage exists
   
   let storeName = this.appSettingsService.getStoreNameFromUrl();
   
   

   this.vendorService.getDetails(storeName).subscribe(mappedStore => {
    
    this.vendorDetails = mappedStore;

   //  this.s3Path   = this.appSettingsService.getS3Path('vendor') + this.vendorDetails.vendor_id + "/" + this.vendorDetails.file_name;
     
     console.log("mappedStore",this.vendorDetails);
    
   });
   }

}
