import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject,of } from 'rxjs';
import { map,mergeMap,catchError } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public fImg: string = "";
  constructor(private http: HttpClient) { }
  public mappedOptionsById: {[key: string]: OptionInterface};
  public getParentProductsByCategory(vendorID:any, variant: string = '', category:string=''): Observable<Array<CategoryInterface>> {
    let params: HttpParams = new HttpParams();
    params = params.set('id',vendorID);
    params = params.set('variant',variant);
    params = params.set('category',category);

    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params
    };
    console.log('Request Params',params);
    return this
            .http
            .get('/inventory/productByVendor/',{params: params})
            .pipe(map(resp => {
              console.log('Resp By Vendor',resp);
              const categories: Array<CategoryInterface> = [];
              let httpResponse:any = resp;
          if (!httpResponse.status || httpResponse.status !== 'success') {
          return categories;
          }
          
          if (!httpResponse.data || !Array.isArray(httpResponse.data)) {
            return categories;
          }

          // Prepare categories
          const temp: {[key: string]: CategoryInterface} = {};
          for (const item of httpResponse.data) {
            if (typeof temp[item.category_id] === 'undefined') {
              temp[item.category_id] = {
                id: item.category_id,
                name: item.category_name,
                selected: false,
                price: item.price,
                image: item.image,
                products: []
              };
            }

            const product: CategryProductInterface = {
              id: item.product_id,
              name: item.product_name,
              sku: item.sku,
              price: item.price,
              originalPrice:parseFloat(item.original_price),            
              image: item.image,
              selected: false,
              created_time: item.created,
            };

            temp[item.category_id].products.push(product);

          }

          // Convert as Array
          const keys = Object.keys(temp);
          for (const cat of keys) {
            categories.push(temp[cat]);
          }

          return categories;
    }));
  }
  
  /**
   * getVariants
   */
  public getVariants(): Observable<{[key: string]: OptionInterface}> {

    if (this.mappedOptionsById) {
      of(this.mappedOptionsById);
    }

    return this
      .http
      .get<HttpResponse<any>>('/inventory/all_variants')
      .pipe(map((httpResponse: any) => {
        console.log(httpResponse);
        const mappedOptionsById: {[key: string]: OptionInterface} = {};
        if (httpResponse && httpResponse.data) {
          for (const data of httpResponse.data) {
            if (!mappedOptionsById[data.variant_id]) {
              mappedOptionsById[data.variant_id] = {
                id: data.variant_id,
                name: data.variant,
                position: parseInt(data.priority, 10),
                values: []
              };
            }

            const value: OptionValuInterface = {
              id: data.variant_value_id,
              name: data.value,
              shortCode: data.short_code
            };

            mappedOptionsById[data.variant_id].values.push(value);
          }
        }

        this.mappedOptionsById = mappedOptionsById;
        return mappedOptionsById;
      }));
  }

  public getProductDetails(productIds: Array<string>, vendorID: string = '0'): Observable<{[key: string]: ProductInterface}>
  {

    let params: HttpParams = new HttpParams();
    params = params.set('order_type', 'SO');
    params = params.set('vendor_id', vendorID);
    for (let i = 0; i < productIds.length; i++) {
      params = params.set('product_ids[' + i + ']', productIds[i]);
    }
    
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: params
    };

    return this
      .http
      .get<HttpResponse<any>>('/inventory/productDetails/', {params: params})
      .pipe(
        map(resp => {
          let httpResponse:any = resp;
          console.log('Return Reps',httpResponse);
          if (!httpResponse.data || !Array.isArray(httpResponse.data)) {
            return {};
          }
          
          let mappedProductsById: {[key: string]: ProductInterface} = {};
          for (const data of httpResponse.data) {
            // Map product
            if (!mappedProductsById[data.product_id]) {

              const breadcrumbs:BreadcrumbInterface = {
                product_id:data.product_id,
                product_name:data.parent_product_name,
                category_id:data.product_id,
                category_name:data.category_name
              };
              mappedProductsById[data.product_id] = {
                id: data.product_id,
                sku: data.product_sku,
                name: data.parent_product_name,
                price: parseFloat(data.price),
                description: data.product_description,
                materialContent: data.material_content,
                careInstructions: data.care_instructions,
                made_in_usa: data.product_made_in_usa,
                wrinkle_free_knit: data.product_wrinkle_free_knit,
                regular_fit: data.product_regular_fit,
                slim_fit: data.product_slim_fit,
                loose_fit: data.product_loose_fit,
                available: true,
                featuredVariant: data.variant_sku,
                featuredImage: data.p_img,
                images: [],
                options: ['size', 'color'],
                variants: {},
                colors: [],
                size: [],
                breadcrumb:breadcrumbs,
                video:data.p_video
              };
            }

            //Prepare Images
            if(data.p_img)
            {
              const img: ProductImageInterface = {
                variant_id: data.img_variant_id,
                img_name : data.p_img
              };
              mappedProductsById[data.product_id].images.push(img);
            }
            // Prepare variants
            const variant: VariantInterface = {
              parent_id: data.product_id,
              id: data.product_variant_id,
              sku: data.variant_sku,
              name: data.product_variant_name,
              price: parseFloat(data.price),
              variant_price: parseFloat(data.variant_price),
              quantity: data.vendor_quantity,
              available: true,
              options: []
            };
            console.log('this.mappedOptionsById', this.mappedOptionsById);
            const variant_ids = data.variant_ids.split(',');
            const variant_value_ids = data.variant_value_ids.split(',');
            if (variant_ids.length && variant_value_ids.length) {
              for (const variant_id of variant_ids) {
                if (this.mappedOptionsById[variant_id]) {
                  const option: OptionInterface = JSON.parse(JSON.stringify(this.mappedOptionsById[variant_id]));
                  for (const variant_value_id of variant_value_ids) {
                  for (const vv of option.values) {
                      if (vv.id === variant_value_id) {
                        option.selectedValue = vv;

                        if (variant_id == 1) {
                          if(!this.checkIfVariantsExits(mappedProductsById[data.product_id].colors, vv.shortCode)) {
                            mappedProductsById[data.product_id].colors.push({shortCode: vv.shortCode, value: vv.name});
                          }
                            
                        } else {
                          if(!this.checkIfVariantsExits(mappedProductsById[data.product_id].size, vv.shortCode))
                            mappedProductsById[data.product_id].size.push({shortCode: vv.shortCode, value: vv.name});
                        }
                        
                      }
                    }
                  }
                  variant.options.push(option);
                }
              }
            }

            mappedProductsById[data.product_id].variants[variant.sku] = variant;

          }
          return mappedProductsById;
        })
      );
  }


  public checkIfVariantsExits(source: Array<any>, key: string){
    let flag = false;
    for (const row of source) {
      if (row.shortCode === key) {
        flag = true;
        break;
      }
    }
    return flag;
  }
  


  public createOrder(form: any) {
    let params: HttpParams = new HttpParams();
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    return this.
            http.
            post('/sales_order/create_front_order', form, httpOptions);
  }


  public getProductImages(productId: any) {
    let params: HttpParams = new HttpParams();
    params = params.set('productID', productId);

    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    return this.
            http.
            get('/inventory/getProductImages/', {params: params});
  }

}
export interface CategoryInterface {
  id: string;
  name: string;
  price:number;
  image: string;
  selected?: boolean;
  products: Array<CategryProductInterface>;
}

export interface CategryProductInterface {
  id: string;
  name: string;
  sku: string;
  image: string;
  originalPrice?: number;
  price:number;
  selected?: boolean;
  created_time:string;
}

export interface ProductInterface {
  id: string;
  sku: string;
  name: string;
  price: number;
  materialContent:string,
  careInstructions:string,
  description: string;
  made_in_usa: string,
  wrinkle_free_knit: string,
  regular_fit: string,
  slim_fit: string,
  loose_fit: string,
  available: boolean;
  featuredImage: string;
  featuredVariant: string;
  images: Array<ProductImageInterface>;
  options: Array<string>;
  variants: {[key: string]: VariantInterface};
  colors: Array<any>;
  size: Array<any>;
  breadcrumb: BreadcrumbInterface;
  video:string;
}


export interface OptionInterface {
  id: string;
  name: string;
  position: number;
  selectedValue?: OptionValuInterface;
  values?: Array<OptionValuInterface>;
}

export interface OptionValuInterface {
  id: string;
  name: string;
  shortCode: string;
}

export interface VariantInterface {
  parent_id:number;
  id: string;
  sku: string;
  name: string;
  price: number;
  variant_price: number;
  quantity: number;
  available: boolean;
  options: Array<OptionInterface>;
  orderedQuantity?: number;
}

export interface BreadcrumbInterface
{
  product_id:number;
  product_name:string;
  category_id:number;
  category_name:string;
}

export interface ProductImageInterface
{
  variant_id:number;
  img_name:string;
}