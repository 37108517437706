import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { AppSettingsService } from '../../../services/app-settings.service';
import { ProductInterface } from '../../../services/product.service';
@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.css']
})
export class ProductImagesComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: any;
  @Input() private product: ProductInterface;
  public showVideo = false;
  private video: string;
  private updatedImages = [];
  public baseurl: string = "";
  public dispImg:string = "";
  public imgType: any;
  constructor(private appSetting: AppSettingsService) { }
 
  
  ngOnInit() {
    this.baseurl = this.appSetting.getBaseUrl();
    if (window.innerWidth < 768 ) {
      this.imgType = "small";
     } else {
       this.imgType = "medium";
     }
     console.log('oninit Images ',this.product);
  }
  ngOnChanges()
  {
    if (window.innerWidth < 768 ) {
      this.imgType = "small";
     } else {
       this.imgType = "medium";
     }
	console.log('on changes Images',this.product);
    if (this.product) {
	 
      this.updatedImages = [];
      for (const img of this.product.images) {
        if (this.updatedImages.indexOf(img.img_name) === -1) {
          this.updatedImages.push(img.img_name);
        }
      }
 
	if(this.updatedImages.length){
        if (this.imgType ==="medium") {
		  let splitImg = this.updatedImages[0].split('.');
		  let image = splitImg[0] +'_'+'medium'+'.'+splitImg[1];
		  this.dispImg = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+image;
		  } else {
		  let splitImg = this.updatedImages[0].split('.');
		  let image = splitImg[0] +'_'+'small'+'.'+splitImg[1];
		  this.dispImg = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+image;
		  }
        
      }
      else{
      this.dispImg = "https://s3.amazonaws.com/inventory-clara/images/nophoto2.jpg";
      }
    }
  }
  showImg(img:string)
  {
    
    if (this.imgType ==="medium") {
      let splitImg = img.split('.');
      let image = splitImg[0] +'_'+'medium'+'.'+splitImg[1];
      this.dispImg = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+image;
      } else {
      let splitImg = img.split('.');
      let image = splitImg[0] +'_'+'small'+'.'+splitImg[1];
      this.dispImg = "https://s3.amazonaws.com/inventory-clara/product-images/"+this.product.id+"/"+image;
      }
  }

}
